import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  const [openContact, SetOpenContact] = React.useState(false)

  return (
    <Layout formTrigger={openContact} cb={SetOpenContact}>
      <SEO title="Platform" />
      <section>
        <div className="container terms-content">
          <h1>WEBSITE DISCLAIMER</h1>
          <small>Last updated [October 27, 2020]</small>

          <h6>INTRODUCTION</h6>

          <p>
            The information provided by Knowledge Capital Services Pvt Ltd.
            (“we,” “us” or “our”) on www.kcapital-us.com (the “Site”) is for
            general informational purposes only. All information on the Site is
            provided in good faith, however we make no representation or
            warranty of any kind, express or implied, regarding the accuracy,
            adequacy, validity, reliability, availability or completeness of any
            information on the Site.
          </p>

          <p>
            Under no circumstance shall we have any liability to you for any
            loss or damage of any kind incurred as a result of the use of the
            site or reliance on any information provided on the site. Your use
            of the site and your reliance on any information on the site is
            solely at your own risk.
          </p>

          <h6>EXTERNAL LINKS DISCLAIMER FOR WEBSITE</h6>

          <p>
            The Site may contain (or you may be sent through the Site links to
            other websites or content belonging to or originating from third
            parties or links to websites and features in banners or other
            advertising. Such external links are not investigated, monitored, or
            checked for accuracy, adequacy, validity, reliability, availability
            or completeness by us.
          </p>

          <p>
            We do not warrant, endorse, guarantee, or assume responsibility for
            the accuracy or reliability of any information offered by
            third-party websites linked through the site or any website or
            feature linked in any banner or other advertising. We will not be a
            party to or in any way be responsible for monitoring any transaction
            between you and third-party providers of products or services.
          </p>

          <h6>PROFESSIONAL DISCLAIMER FOR WEBSITE</h6>

          <p>
            <b>
              The Site cannot and does not contain
              [medical/legal/fitness/health/other] advice. The
              [legal/medical/fitness/health/other] information is provided for
              general informational and educational purposes only and is not a
              substitute for professional advice.
            </b>
          </p>

          <p>
            Accordingly, before taking any actions based upon such information,
            we encourage you to consult with the appropriate professionals. We
            do not provide any kind of [medical/legal/fitness/health/other]
            advice. The use or reliance of any information contained on this
            site [or our mobile application] is solely at your own risk.
          </p>

          <h6>TESTIMONIALS DISCLAIMER FOR WEBSITE</h6>

          <p>
            The Site may contain testimonials by users of our products and/or
            services. These testimonials reflect the real-life experiences and
            opinions of such users. However, the experiences are personal to
            those particular users, and may not necessarily be representative of
            all users of our products and/or services. We do not claim, and you
            should not assume, that all users will have the same experiences.
            Your individual results may vary.
          </p>

          <p>
            The testimonials on the Site are submitted in various forms such as
            text, audio and/or video, and are reviewed by us before being
            posted. They appear on the Site verbatim as given by the users,
            except for the correction of grammar or typing errors. Some
            testimonials may have been shortened for the sake of brevity where
            the full testimonial contained extraneous information not relevant
            to the general public.
          </p>

          <p>
            The views and opinions contained in the testimonials belong solely
            to the individual user and do not reflect our views and opinions.
            [We are not affiliated with users who provide testimonials, and
            users are not paid or otherwise compensated for their testimonials.]
          </p>

          <p>
            The testimonials on the Site are not intended, nor should they be
            construed, as claims that our products and/or services can be used
            to diagnose, treat, mitigate, cure, prevent or otherwise be used for
            any disease or medical condition. No testimonials have been
            clinically proven or evaluated.
          </p>
        </div>
      </section>
    </Layout>
  )
}
